import "../../../stylesheets/cl/lp/index.scss";
import "../../../stylesheets/cl/lp/animate.css";
import "../../../stylesheets/cl/lp/bootstrap.css";
import "./modernizr@3.6.0.js";

$(document).on('turbolinks:load', function() {

  if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
    const WOW = require('wowjs');
    window.wow = new WOW.WOW({
        live: false
    });
    window.wow.init();
  }

    /**** ヘッダー ****/
    if (!$('header').hasClass('is-web-view')) {
      $(window).on("load resize", function() {
        var height=$('header').outerHeight();
        $('#js-top-content').css('padding-top', height);
      });
    }

});
